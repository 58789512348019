<template>
  <v-main :class="xs ? '' : 'bg-light-grey'">
    <v-container
      v-if="!xs"
      :class="lgAndUp ? 'w-50' : 'w-75'"
      style="max-width: 1000px;"
    >
      <v-row>
        <v-col>
          <v-sheet rounded="xl">
            <v-card class="rounded-t-xl rounded-b-0">
              <v-toolbar
                color="primary-darken-1"
                density="prominent"
              >
                <v-img
                  aspect-ratio="1.7"
                  max-height="110"
                  class="my-2"
                  src="/storage/logo/logo_light.svg"
                />
              </v-toolbar>
              <v-toolbar
                color="primary"
                density="compact"
              >
                <v-toolbar-title class="text-center text-body-1 text-white ma-0">
                  {{ t('login.welcome_long', { city: getSetting('city_name') }) }}
                </v-toolbar-title>
              </v-toolbar>
            </v-card>
            <v-container class="mt-5">
              <slot />
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <v-toolbar
        color="primary-darken-1"
        density="prominent"
      >
        <v-img
          aspect-ratio="1.7"
          max-height="110"
          class="my-2"
          src="/storage/logo/logo_light.svg"
        />
      </v-toolbar>
      <v-toolbar
        color="primary"
        density="compact"
      >
        <v-toolbar-title class="text-body-1 text-white text-center ma-0">
          {{ t('login.welcome', { smpw: getSetting('smpw_acronym'), city: getSetting('city_name') }) }}
        </v-toolbar-title>
      </v-toolbar>
      <v-container class="mt-5">
        <slot />
      </v-container>
    </div>
  </v-main>
</template>

<script setup lang="ts">
import { useSettings } from '@/composables';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

const { t } = useI18n();
const { xs, lgAndUp } = useDisplay();
const { getSetting } = useSettings();
</script>
