<template>
  <v-footer
    class="text-center bg-primary-darken-1 justify-space-evenly footer mt-10"
    :class="{ menu_open: !isRailMode && !mobile }"
  >
    <v-row class="mb-0 pt-6 pb-3">
      <v-col
        cols="12"
        sm="4"
        class="d-flex flex-column justify-center align-center"
      >
        <div class="d-flex flex-column">
          <v-btn
            v-if="getSetting('terms_and_conditions_enabled')"
            variant="text"
            to="/policies/termsandconditions"
            :text="t('policies.terms')"
            class="d-flex justify-start"
          />
          <v-btn
            v-if="getSetting('privacy_policy_enabled')"
            variant="text"
            to="/policies/privacy"
            class="d-flex justify-start"
            :text="t('policies.privacy')"
          />
          <v-btn
            v-if="getSetting('cookie_consent_enabled')"
            variant="text"
            to="/policies/cookies"
            class="d-flex justify-start"
            :text="t('policies.cookie')"
          />
          <language-switcher
            class="d-flex justify-start"
          />
          <v-btn
            variant="text"
            class="d-flex justify-start"
            prepend-icon="fas fa-fw fa-sign-out-alt"
            :text="t('login.log_out')"
            @click="handleLogout"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-img
          src="/storage/logo/logo_light.svg"
          height="85px"
        />
        <p class="text-uppercase text-body-2 mt-2 text-white">
          &copy; {{ thisYear() }} - {{ getSetting('domain') }} - {{ version }}
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        class="d-flex flex-column justify-center align-center"
      >
        <v-dialog
          v-model="dialog"
          :fullscreen="xs"
          :width="!xs ? 'auto' : undefined"
          :loading="loading"
          max-width="750"
          @update:model-value="onDialogVisibilityChange"
        >
          <template #activator="{ props: dialogProps }">
            <v-card
              rounded
              v-bind="dialogProps"
              width="200px"
            >
              <v-card-text class="pa-2 pt-3 bg-error text-uppercase text-subtitle-1">
                {{ t('dayoverseer.emergency_help') }}
              </v-card-text>
              <v-card-text class="pa-2 pt-3 text-uppercase text-subtitle-1 text-black">
                {{ t('dayoverseer.contact_information') }}
              </v-card-text>
            </v-card>
          </template>

          <template #default="{ isActive }">
            <global-card
              :title="t('dayoverseer.emergency_information')"
              closable
              @close="isActive.value = false"
            >
              <v-card
                v-if="shiftCaptainContact"
                variant="flat"
              >
                <v-row>
                  <v-col align="center">
                    <user-avatar :user="shiftCaptainContact" />
                  </v-col>
                  <v-col>
                    <h5>{{ shiftCaptainContact.name }}</h5>
                    <p>
                      {{ getSetting('captain_label') }} {{ t('dayoverseer.contact') }}
                    </p>
                    <phone-number
                      class="mb-2"
                      :number="shiftCaptainContact.mobile_phone"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                v-for="dayOverseer in dayOverseerContacts"
                :key="dayOverseer.id"
                variant="flat"
                class="pt-5"
              >
                <v-row>
                  <v-col align="center">
                    <user-avatar :user="dayOverseer.user" />
                  </v-col>
                  <v-col>
                    <h5> {{ dayOverseer.user.name }}</h5>
                    <p>
                      {{ dayOverseer.order_name }} {{ t('dayoverseer.title') }}
                    </p>
                    <p v-if="dayOverseer.notes">
                      {{ dayOverseer.notes }}
                    </p>
                    <phone-number
                      class="mb-2"
                      :number="dayOverseer.user.mobile_phone"
                    />
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                v-if="emergencyFaq"
                variant="flat"
              >
                <v-card-title>
                  <div class="text-left text-wrap text-h6">
                    {{ emergencyFaq.question }}
                  </div>
                </v-card-title>
                <v-card-text>
                  <div
                    class="tiptap text-font"
                    v-html="emergencyFaq.answer"
                  />
                </v-card-text>
              </v-card>
              <template #actions>
                <v-spacer />
                <v-btn
                  :text="t('actions.close')"
                  color="dark-grey"
                  @click="isActive.value = false"
                />
              </template>
            </global-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script setup lang="ts">
import { useDate, useSettings } from '@/composables/index.ts';
import { useMenu } from '@/composables/useMenu';
import { useErrorStore } from '@/stores/errors';
import axios from 'axios';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import UserAvatar from './images/UserAvatar.vue';

defineProps<{
  version?: string;
}>();
const { t } = useI18n();
const { getSetting } = useSettings();
const { thisYear } = useDate();
const { isRailMode } = useMenu();
const { mobile, xs } = useDisplay();
const { handleError } = useErrorStore();

const dialog = ref(false);
const loading = ref(false);
const shiftCaptainContact = ref<App.Models.User>();
const dayOverseerContacts = ref<Array<App.Models.DayOverseer>>([]);
const emergencyFaq = ref<App.Models.Faq>();

async function loadFooterData() {
  if (dayOverseerContacts.value.length) {
    return;
  }
  try {
    loading.value = true;
    const response = await axios.get('/api/menu/footer');
    shiftCaptainContact.value = response.data.data.shiftCaptainContact;
    dayOverseerContacts.value = response.data.data.dayOverseerContacts;
    emergencyFaq.value = response.data.data.emergencyFaq;
  }
  catch (e) {
    handleError(e, t('errors.cannot_load_widgets'));
  }
  finally {
    loading.value = false;
  }
}

function onDialogVisibilityChange(visible: boolean) {
  if (visible) {
    loadFooterData();
  }
}

function handleLogout(event: KeyboardEvent) {
  event.preventDefault();
  const form = document.getElementById('logout-form') as HTMLFormElement;
  form.submit();
}
</script>

<style scoped>
.footer {
  transition: margin 0.2s;
  margin-left: 0px;
}

.footer.menu_open {
  transition: margin 0.2s;
  margin-left: 250px;
}
</style>
