import { useDate, usePermissions } from '@/composables';
import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useErrorStore } from './errors';

export const useScheduleStore = defineStore('schedule', () => {
  const {
    toUniversalDate,
    getAsDate,
  } = useDate();
  const { showSnackMessage } = useErrorStore();
  const { can } = usePermissions();
  const { t } = useI18n();

  const selectedDate = ref<string>(sessionStorage.getItem('scheduleDate') ?? toUniversalDate(getAsDate()));
  const weekView = ref(false);
  const readOnly = computed(() => !can('manage-shifts'));
  const dayShiftPlan = ref<DayShiftPlan>();
  const timeBands = ref();
  const loadingWeekView = ref(true);
  const loadingDayView = ref(true);
  const lastLoadedDate = ref();
  const loading = ref(false);
  const publishers = ref<App.Models.User[]>([]);

  function doesUserHaveDependentLinkedPublisher(
    shift: App.ApiResources.ShiftResource,
    publisher: any,
  ) {
    if (!shift || !publisher) {
      return [];
    }
    if (publisher.child_users.length === 0) {
      return [];
    }

    const childUserIds = publisher.child_users.map((child) => child.id);
    const childUsersOnShift = shift.assigned_publishers.filter((assigned_publisher) => {
      return childUserIds.includes(assigned_publisher.id);
    });

    if (childUsersOnShift.length === 0) {
      return [];
    }

    const childUsersThatWillBeRemoved = [];
    childUsersOnShift.forEach(child => {
      const childsParentUsersOnShift = shift.assigned_publishers.filter((pub) => {
        return pub.child_users.map((a) => a.id).includes(child.id);
      });

      if (childsParentUsersOnShift.length <= 1) {
        childUsersThatWillBeRemoved.push(child);
      }
    });
    return childUsersThatWillBeRemoved;
  }

  function isPublisherAssignedInTimeslot(
    shift: App.ApiResources.ShiftResource,
    publisher: any,
  ) {
    let found = false;
    let loc = null;

    if (!shift || !publisher) {
      return {
        adjacent: found,
        location: loc,
      };
    }
    /** @ts-ignore */
    const sameTimeslotShifts = dayShiftPlan.value?.filter((shft) =>
      shft.begins === shift.begins,
    );

    sameTimeslotShifts.forEach((shft) => {
      shft.locations.forEach((location) => {
        if (location.shift.assigned_publishers.filter((pub) => pub.id === publisher.id).length > 0) {
          found = true;
          loc = location;
        }
      });
    });
    return {
      found,
      location: loc,
    };
  }

  function doesUserHaveAdjacentShift(
    shift: App.ApiResources.ShiftResource,
    publisher: any,
  ) {
    let found = false;
    let loc = null;

    if (!shift || !publisher) {
      return {
        adjacent: found,
        location: loc,
      };
    }
    /** @ts-ignore */
    const adjacentShifts = dayShiftPlan.value?.filter((shft) =>
      shft.ends === shift.begins || shft.begins === shift.ends,
    );

    adjacentShifts.forEach((shft) => {
      shft.locations.forEach((location) => {
        if (location.shift.assigned_publishers.filter((pub) => pub.id === publisher.id).length > 0) {
          found = true;
          loc = location;
        }
      });
    });
    return {
      found,
      location: loc,
    };
  };
  function appendDetails(publisher) {
    const extendedPub = publishers.value.find((p) => p.id === publisher.user_id);
    if (!extendedPub || !publisher) {
      return publisher;
    }
    return { ...publisher, ...extendedPub, id: publisher.user_id };
  }

  async function pollDayShiftPlan() {
    await loadDayShiftPlan({ force: false, update: true });
  }

  async function getShiftPlanUpdates() {
    await loadDayShiftPlan({ force: true, update: true });
  }

  /**
   * Loads the day shift plan.
   *
   * @param options - The options for loading the day shift plan.
   * @param options.force - If true, forces a new request even if a request is already in progress. Defaults to true.
   * @param options.update - If true, makes a request that only refreshes data that changed after the last request. Defaults to false.
   */
  async function loadDayShiftPlan(options: { force?: boolean; update?: boolean }) {
    const force = options.force ?? true;
    const update = options.update ?? false;
    try {
      if (!loading.value || force) {
        loading.value = true;
        sessionStorage.setItem('scheduleDate', selectedDate.value);
        const r = await axios.get(`/api/shifts/schedule/day?date=${selectedDate.value}&updated_since=${update ? lastLoadedDate.value : ''}`);

        // When updating merge the new data with the existing data
        publishers.value = update ? publishers.value.concat(r.data.data.publishers) : r.data.data.publishers;

        const dsp = r.data.data.slots;

        dsp.forEach((slot: any, slotIndex) => {
          slot.locations.forEach((shift: any, shiftIndex) => {
            if (shift.shift.captain) {
              shift.shift.captain = appendDetails(shift.shift.captain);
            }
            shift.shift.assigned_brothers.forEach((publisher: any, publisherIndex) => {
              dsp[slotIndex].locations[shiftIndex].shift.assigned_brothers[publisherIndex] = appendDetails(publisher);
            });
            shift.shift.assigned_elsewhere_publishers.forEach((publisher: any, publisherIndex) => {
              dsp[slotIndex].locations[shiftIndex].shift.assigned_elsewhere_publishers[publisherIndex] = appendDetails(publisher);
            });
            shift.shift.assigned_publishers.forEach((publisher: any, publisherIndex) => {
              dsp[slotIndex].locations[shiftIndex].shift.assigned_publishers[publisherIndex] = appendDetails(publisher);
            });
            shift.shift.available_captains.forEach((publisher: any, publisherIndex) => {
              dsp[slotIndex].locations[shiftIndex].shift.available_captains[publisherIndex] = appendDetails(publisher);
            });
            shift.shift.available_publishers.forEach((publisher: any, publisherIndex) => {
              dsp[slotIndex].locations[shiftIndex].shift.available_publishers[publisherIndex] = appendDetails(publisher);
            });
            shift.shift.cancelled_publishers.forEach((publisher: any, publisherIndex) => {
              dsp[slotIndex].locations[shiftIndex].shift.cancelled_publishers[publisherIndex] = appendDetails(publisher);
            });
            shift.shift.limit_reached_publishers.forEach((publisher: any, publisherIndex) => {
              dsp[slotIndex].locations[shiftIndex].shift.limit_reached_publishers[publisherIndex] = appendDetails(publisher);
            });
            shift.shift.publishers.forEach((publisher: any, publisherIndex) => {
              dsp[slotIndex].locations[shiftIndex].shift.publishers[publisherIndex] = appendDetails(publisher);
            });
            shift.shift.adjacent_publishers.forEach((publisher: any, publisherIndex) => {
              dsp[slotIndex].locations[shiftIndex].shift.adjacent_publishers[publisherIndex] = appendDetails(publisher);
            });

            if (update) {
              dayShiftPlan.value[slotIndex].locations.find((l) => l.id === shift.id).shift = shift.shift;
            }
          });
        });
        if (!update) {
          dayShiftPlan.value = dsp;
          timeBands.value = r.data.data.bands;
        }
        lastLoadedDate.value = r.data.data.updated_since;
        loading.value = false;
      }
    }
    catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
        showSnackMessage(`${t('shift.detail.cannot_load')}: ${selectedDate.value}`);
        loading.value = false;
      }
    }
  }

  return {
    loadDayShiftPlan,
    pollDayShiftPlan,
    getShiftPlanUpdates,
    doesUserHaveAdjacentShift,
    isPublisherAssignedInTimeslot,
    doesUserHaveDependentLinkedPublisher,
    dayShiftPlan,
    loadingDayView,
    loadingWeekView,
    selectedDate,
    weekView,
    publishers,
    readOnly,
    lastLoadedDate,
  };
});
